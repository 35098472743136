export default class PaymentRent {
    constructor() {
        this.id = "";
        this.chargingId = "";
        this.description = "";
        this.nextDueDate = "";
        this.value = 0;
    }
    update(data) {
        this.id = data.id;
        this.active = data.active;
        this.nextDueDate = data.nextDueDate;
        this.value = data.value;
        this.description = data.description;
    }
}