<template>
  <div class="margin">
    <Molded>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="7" xl="7">
          <Select title="Cliente" field="customer" :formName="formName" :disabled="id" :required="true"
            url="/api/v1/crm/customer/select-all" v-model="charging.customerSupplier">
          </Select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <InputNumber title="ID Vinculado" field="companyId" :required="true" :maxLength="10"
            v-model="charging.companyId" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <DateTime title="Início do Contrato" field="dueDate" format="DD/MM/YYYY" type="date" placeholder
            :formName="formName" :required="true" v-model="charging.startContract" />
        </b-col>
      </b-row>
      <InputText title="Descrição" field="description" :required="true" :maxLength="200"
        v-model="charging.description" />
      <b-row>
        <b-col sm="2">
          <CheckboxSimple title="Bloqueado" v-model="charging.confirmedBlocked" />
        </b-col>
        <b-col sm="6">
          <CheckboxSimple title="Negativado/Serasa" v-model="charging.confirmedNegative" />
        </b-col>
      </b-row>
    </Molded>
    <br>

    <b-tabs v-if="id">
      <b-tab title="Avulsa">
        <BilletList :chargingId="id" />
      </b-tab>
      <b-tab title="Recorrente">
        <RecurringList :chargingId="id" />
      </b-tab>
      <b-tab title="Notificações">
        <Emails :chargingId="id" />
      </b-tab>
      <b-tab title="Observações">
        <TextArea title="Observações (Uso Interno)" field="observation" :required="false" :maxLength="500"
          v-model="charging.observation" :markFormDirty="false" />
      </b-tab>
    </b-tabs>
    <br />
  </div>
</template>

<script>

import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";

import Charging from "@/components/modules/billing/charging/Charging.js";

import BilletList from '../billet/BilletList.vue'
import RecurringList from '../recurring/RecurringList.vue'
import Emails from '../notifications/Emails.vue'

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ChargingCreateUpdate",
  components: {
    InputText,
    InputNumber,
    Select,
    TextArea,
    Button,
    Molded,
    BilletList, DateTime, RecurringList, Emails, CheckboxSimple
  },
  data() {
    return {
      id: this.$route.params.id,
      formName: "customerSupplierCreate",
      charging: new Charging(),
      urlCreate: "/api/v1/billing/charging/create",
      urlUpdate: "/api/v1/billing/charging/update",
      urlGetById: "/api/v1/billing/charging/get-by-id",
    };
  },
  created() {
    if (this.id) this.getById();
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", ["removeLoading", "hideVodal", "hideSideBar"]),
    create() {
      let params = { url: this.urlCreate, obj: this.charging };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.$router.replace({
            name: "chargingUpdate",
            params: { id: response.content.id },
          });
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.charging };
      this.putApi(params).then(() => {
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.id } };
      this.getApi(params).then((response) => {
        this.charging.update(response.content);
        let self = this;
        setTimeout(function () {
          self.removeLoading(["panel"]);
        }, 100);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}
</style>
