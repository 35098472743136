export default class CustumerSupplier {
    constructor() {
        this.id = "";
        this.startContract = "";
        this.customerSupplier = { id: "", content: "" };
        this.description = "";
        this.observation = "";
        this.confirmedBlocked = false;
        this.confirmedNegative = false;
        this.companyId = 0;
    }
    update(data) {
        this.id = data.id;
        this.startContract = data.startContract;
        this.customerSupplier = { id: data.customerSupplierId, content: data.customerSupplier.person.companyName };
        this.description = data.description;
        this.observation = data.observation;
        this.confirmedBlocked = data.confirmedBlocked;
        this.confirmedNegative = data.confirmedNegative;
        this.companyId = data.companyId;
    }
}