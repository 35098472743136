<template>
  <div>
    <b-row>
      <b-col sm="12">
        <InputText title="Descrição" field="name" :formName="formName" :required="true" :maxLength="50"
          v-model="recurring.description" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
        <DateTime title="Proximo Vencimento" field="dueDate" format="DD/MM/YYYY" type="date" placeholder
          :formName="formName" :required="true" v-model="recurring.nextDueDate" />
      </b-col>
      <b-col sm="3">
        <InputDecimal title="Valor" field="value" :formName="formName" :required="true" :markFormDirty="false"
          :maxLength="5" type="float" v-model="recurring.value" />
      </b-col>
    </b-row>
    <b-row v-if="recurring.id">
      <b-col sm="12">
        <RadioGroup title="Status" field="status" :formName="formName" :options="[
          { text: 'Ativo', value: true },
          { text: 'Inativo', value: false },
        ]" v-model="recurring.active" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="btnSaveBillet" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="saveRecurring" />
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";

import Recurring from "@/components/modules/billing/recurring/Recurring.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "RecurringCreateUpdate",
  components: {
    InputText,
    InputDecimal,
    DateTime,
    Button,
    RadioGroup
  },
  props: {
    chargingId: String,
  },
  data() {
    return {
      formName: "recurringCreateUpdate",
      recurring: new Recurring(),
      urlCreate: "/api/v1/billing/recurring/create",
      urlUpdate: "/api/v1/billing/recurring/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    saveRecurring() {
      this.recurring.chargingId = this.chargingId;

      if (this.recurring.id) {
        let params = { url: this.urlUpdate, obj: this.recurring };
        this.putApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["btnSaveBillet"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.recurring };
        this.postApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["btnSaveBillet"]);
        });
      }
    },
    update(data) {
      this.recurring = new Recurring();
      this.recurring.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "recurringCreate") {
          this.resetValidation(this.formName);
          this.recurring = new Recurring();
        }
        if (event.name == "recurringUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
