<template>
  <div>
    <ViewTemplateWithSalveCancel :panel="panel">
      <div slot="content-buttons">
        <Button v-if="id" _key="btnChargingCreate" title="Cobrança" classIcon="fas fa-plus-circle" type="primary"
          size="small" eventName="customerCreate" :clicked="create" />
      </div>
      <div slot="content-main">
        <ChargingCreateUpdate :formName="panel.formName" />
      </div>
    </ViewTemplateWithSalveCancel>
  </div>
</template>

<script>

import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";
import ChargingCreateUpdate from '../../../components/modules/billing/charging/ChargingCreateUpdate.vue'

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ChargingCreateUpdateView",
  components: {
    Button,
    Molded,
    ViewTemplateWithSalveCancel,
    ChargingCreateUpdate
  },
  data() {
    return {
      id: this.$route.params.id,
      panel: {
        module: "Cobrança",
        title: "Cobrança",
        formName: "chargingCreateUpdate",
        showFilter: false,
        showSearch: false,
        showButtons: true,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "chargingCreate",
      });
      this.removeLoading(["btnChargingCreate"]);
    },
  },
};
</script>
