<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList" :showSearch="false" :showHorizontalFilter="false"
      :propsParam="propsParam">
      <div slot="content-buttons">
        <Button _key="btnBilletCreate" title="Boleto" classIcon="fas fa-plus-circle" type="primary" size="small"
          :clicked="create" />
      </div>
    </ViewTemplateConfiguration>
    <Modal title="Boleto" :width="700" v-show="showModal('billet')">
      <BilletCreateUpdate :chargingId="chargingId" />
    </Modal>
  </div>
</template>

<script>

import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import BilletCreateUpdate from './BilletCreateUpdate.vue'

import { mapMutations, mapGetters } from "vuex";

export default {
  name: "BilletList",
  components: {
    ViewTemplateConfiguration,
    Button,
    Modal, BilletCreateUpdate
  },
  props: {
    chargingId: String,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/billing/billet/get-all",
        urlDeleteAllApi: "/api/v1/billing/billet/delete",
        headerTable: [
          {
            title: "Descrição",
            field: "description",
            type: "link",
            eventName: "billetUpdate"
          },
          {
            title: "Emissão",
            field: "registeredDate",
            type: "dateTime",
            iconSearch: false,
          },
          {
            title: "Vencimento",
            field: "dueDate",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "statusName",
            title: "Status",
            type: "class",
            fieldComparison: "statusName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-primary",
                fieldComparison: "Alteração Solicitada",
              },
              {
                classCss: "text-center badge badge-primary",
                fieldComparison: "A vencer",
              },
              {
                classCss: "text-center badge badge-dark-primary",
                fieldComparison: "Registrado",
              },
              {
                classCss: "text-center badge badge-success",
                fieldComparison: "Liquidado",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Cancelado",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Rejeitado",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Em atraso",
              },
              {
                classCss: "text-center badge badge-warning",
                fieldComparison: "Baixa",
              },
              {
                classCss: "text-center badge badge-warning",
                fieldComparison: "Baixa Solicitada",
              },
            ],
          },
          {
            title: "NF",
            field: "nfse",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "html",
            eventName: "pdfNfse",
          },
          {
            type: "button",
            typeButton: "default",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            button: {
              classIcon: "fa-solid fa-download",
              type: "default",
              size: "small",
              eventName: "downloadNfse",
            },
          },
          {
            title: "Valor",
            field: "value",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            type: "button",
            typeButton: "default",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            button: {
              classIcon: "fa-regular fa-copy",
              type: "default",
              size: "small",
              eventName: "copyLink",
            },
          },
        ],
      },
      propsParam: {
        any: this.chargingId,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "addEvent", "removeLoading"]),
    create() {
      this.openModal("billet");
      this.addEvent({ name: "billetCreate" });
      this.removeLoading(["btnBilletCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "billetUpdate") this.openModal("billet");
        if (event.name == "copyLink") {
          navigator.clipboard.writeText(event.data.link);
          this.$toasted.show("Link copiado...", { type: "info" });
        }

        if (event.name == "downloadNfse") {
          console.log(event.data);

          window.open(event.data.idIntegracao, '_blank');
        }
      },
      deep: true,
    },
  },
};
</script>
<style></style>