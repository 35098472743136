<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList" :showSearch="false" :showHorizontalFilter="false"
      :propsParam="propsParam">
      <div slot="content-buttons">
        <Button _key="btnRecurringCreate" title="Recorrência" classIcon="fas fa-plus-circle" type="primary" size="small"
          :clicked="create" />
      </div>
    </ViewTemplateConfiguration>
    <Modal title="Recorrência" :width="700" v-show="showModal('recurring')">
      <RecurringCreateUpdate :chargingId="chargingId" />
    </Modal>
  </div>
</template>

<script>

import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import RecurringCreateUpdate from './RecurringCreateUpdate.vue'

import { mapMutations, mapGetters } from "vuex";

export default {
  name: "RecurringList",
  components: {
    ViewTemplateConfiguration,
    Button,
    Modal, RecurringCreateUpdate
  },
  props: {
    chargingId: String,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/billing/recurring/get-all",
        urlDeleteAllApi: "/api/v1/billing/recurring/delete",
        headerTable: [
          {
            title: "Ativo",
            field: "activeName",
            type: "html",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            title: "Descrição",
            field: "description",
            type: "link",
            eventName: "recurringUpdate"
          },
          {
            title: "Último Disparo",
            field: "lastShooting",
            type: "date",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            title: "Próximo Disparo",
            field: "nextShooting",
            type: "date",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            title: "Próximo Vencimento",
            field: "nextDueDate",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            title: "Valor",
            field: "value",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
        ],
      },
      propsParam: {
        any: this.chargingId,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "addEvent", "removeLoading"]),
    create() {
      this.openModal("recurring");
      this.addEvent({ name: "recurringCreate" });
      this.removeLoading(["btnRecurringCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "recurringUpdate") this.openModal("recurring");
      },
      deep: true,
    },
  },
};
</script>
<style></style>