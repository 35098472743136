export default class PaymentRent {
    constructor() {
        this.id = "";
        this.chargingId = "";
        this.customerId = "";
        this.description = "";
        this.dueDate = "";
        this.value = 0;
        this.onlyNfse = false;
        this.link = "";
        this.tokenBillet = "";
        this.tokenShipping = "";
    }
    update(data) {
        this.id = data.id;
        this.dueDate = data.dueDate;
        this.value = data.value;
        this.description = data.description;
        this.link = data.link;
        this.tokenBillet = data.tokenBillet;
        this.tokenShipping = data.tokenShipping;
    }
}