<template>
  <div>
    <b-tabs>
      <b-tab title="Dados">
        <b-row>
          <b-col sm="12">
            <InputText title="Descrição" field="name" :formName="formName" :disabled="billet.id != ''" :required="true"
              :maxLength="50" v-model="billet.description" :markFormDirty="false" />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="3">
            <DateTime title="Vencimento" field="dueDate" format="DD/MM/YYYY" type="date" placeholder
              :formName="formName" :required="true" v-model="billet.dueDate" />
          </b-col>
          <b-col sm="3">
            <InputDecimal title="Valor" field="value" :formName="formName" :disabled="billet.id != ''" :required="true"
              :markFormDirty="false" :maxLength="5" type="float" v-model="billet.value" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Token">
        <div>
          <InputText title="Token Remessa" field="name" :formName="formName" :disabled="true" :required="false"
            :maxLength="50" v-model="billet.tokenShipping" :markFormDirty="false" />
        </div>
        <div>
          <InputText title="Token Boleto" field="name" :formName="formName" :disabled="true" :required="false"
            :maxLength="50" v-model="billet.tokenBillet" :markFormDirty="false" />
        </div>
      </b-tab>
    </b-tabs>
    <br>
    <b-row>
      <b-col sm="12">
        <CheckboxSimple title="Cobrança somente para emitir Nfse" v-model="billet.onlyNfse" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="btnSaveBillet" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="saveBillet" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import Billet from "@/components/modules/billing/billet/Billet.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "BilletCreateUpdate",
  components: {
    CheckboxSimple,
    InputText,
    InputDecimal,
    DateTime,
    Button,
  },
  props: {
    chargingId: String,
  },
  data() {
    return {
      formName: "billetCreateUpdate",
      billet: new Billet(),
      urlCreate: "/api/v1/billing/billet/create",
      urlUpdate: "/api/v1/billing/billet/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    saveBillet() {
      this.billet.chargingId = this.chargingId;

      if (this.billet.id) {
        let params = { url: this.urlUpdate, obj: this.billet };
        this.putApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["btnSaveBillet"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.billet };
        this.postApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["btnSaveBillet"]);
        });
      }
    },
    update(data) {
      this.billet = new Billet();
      this.billet.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "billetCreate") {
          this.resetValidation(this.formName);
          this.billet = new Billet();
        }
        if (event.name == "billetUpdate") {
          this.update(event.data);
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-token {
  padding: 20px;
}
</style>